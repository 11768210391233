import React from "react"
import { graphql } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Album from "../components/album";

import { Container } from "react-bootstrap"

import * as styles from "../styles/xlnt-records.module.css"
import { RouteComponentProps } from "@reach/router"

interface XLNTRecordsQueryProps extends RouteComponentProps {
    data: {
        allStrapiAlbum: {
            nodes: {
                slug: string
                releaseDate: Date
                description: {
                    data : {
                        childMdx: {
                            body: string
                        }
                    }
                }
                engDescription: {
                    data : {
                        childMdx: {
                            body: string
                        }
                    }
                }
                title: string
                url: string
                image: {
                    localFile: ImageDataLike
                }
                artist: {
                    slug: string
                    category: {
                        slug: string
                    }
                    website: string
                    xlntManaged: boolean
                    name: string
                }
            }[]
        },
        strapiAlbumPage: {
            background: {
                localFile: ImageDataLike
            }
            // description: {
            //     data : {
            //         childMdx: {
            //             body: string
            //         }
            //     }
            // }
            // engDescription: {
            //     data : {
            //         childMdx: {
            //             body: string
            //         }
            //     }
            // }
        }
    }
}

export default function XLNTRecords( { data, location } : XLNTRecordsQueryProps ) {
    const { allStrapiAlbum, strapiAlbumPage } = data;
    const defaultLang = "hu";
    return (
        <Layout lang="en" pageMeta={{title: "XLNT Records"}} background={strapiAlbumPage.background?.localFile} location={location} defaultLang={defaultLang}>
            <Container className={`${styles.albums_content_wrapper} my-auto`}>
                { allStrapiAlbum.nodes.map(node => {
                    if (!node.artist) {
                        console.warn("No artist set for " +  node.title);
                    } else {
                        return <Album lang="en" data={node} key={node.title} defaultLang={defaultLang}/>
                    }
                })}
            </Container>
        </Layout>
    );
}
  

export const query = graphql`
query XLNTRecordsQueryEn {
    allStrapiAlbum(sort: {fields: [releaseDate], order: DESC} ) {
        nodes {
            slug
            releaseDate
            description {
                data {
                    childMdx {
                        body
                    }
                }
            }
            engDescription {
                data {
                    childMdx {
                        body
                    }
                }
            }
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            height: 400
                            aspectRatio: 1
                            transformOptions: {cropFocus: CENTER}
                        )
                    }
                }
            }
            artist {
                slug
                category {
                    slug
                }
                website
                xlntManaged
                name
            }
            title
            url
        }
    }
    strapiAlbumPage {
        background {
            localFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
}
`